<template>
    <div class="forms-sharing-settings">
        <strong>Veröffentlichungsoptionen</strong>
        <div class="publishing-options" v-if="form">
            <b-form-group
                :label-cols-sm="3"
                :label-cols-lg="4"
                label-for="is-active"
                :label-size="size"
                label="Formularstatus"
                description=""
                :disabled="busy.data || busy.mutation"
            >
                <b-form-radio-group
                    id="is-active"
                    v-model="form.is_active"
                    :options="activeOptions"
                    name="is-active"
                    button-variant="outline-secondary"
                    buttons
                    :size="size"
                    :disabled="busy.data || busy.mutation"
                ></b-form-radio-group>
            </b-form-group>
            <b-form-group
                :label-cols-sm="3"
                :label-cols-lg="4"
                label-for="disable-by-date"
                :label-size="size"
                label="Formular ab bestimmtem Datum deaktivieren"
                description=""
                :disabled="busy.data || busy.mutation"
            >
                <b-checkbox
                    type="checkbox"
                    id="disable-by-date"
                    v-model="form.disable_by_date"
                    :size="size"
                    :disabled="busy.data || busy.mutation"
                    :value="1"
                    :unchecked-value="0"
                />
            </b-form-group>
            <b-form-group
                :label-cols-sm="3"
                :label-cols-lg="4"
                label-for="disable-date"
                :label-size="size"
                description=""
                :disabled="busy.data || busy.mutation"
            >
                <b-datepicker
                    id="disable-date"
                    :size="size"
                    v-model="form.disable_date"
                    :disabled="
                        busy.data || busy.mutation || form.disable_by_date != 1
                    "
                ></b-datepicker>
            </b-form-group>
            <b-form-group
                :label-cols-sm="3"
                :label-cols-lg="4"
                label-for="disable-by-count"
                :label-size="size"
                label="Formular ab bestimmter Anmeldezahl deaktivieren"
                description=""
                :disabled="busy.data || busy.mutation"
            >
                <b-checkbox
                    type="checkbox"
                    id="disable-by-count"
                    v-model="form.disable_by_count"
                    :size="size"
                    :disabled="busy.data || busy.mutation"
                    :value="1"
                    :unchecked-value="0"
                />
            </b-form-group>
            <b-form-group
                :label-cols-sm="3"
                :label-cols-lg="4"
                label-for="disable-count"
                :label-size="size"
                description=""
                :disabled="busy.data || busy.mutation"
            >
                <b-input
                    id="disable-count"
                    :size="size"
                    placeholder="bspw. 150"
                    v-model="form.disable_count"
                    :disabled="
                        busy.data || busy.mutation || form.disable_by_count != 1
                    "
                ></b-input>
            </b-form-group>
        </div>
        <hr />
        <strong>Veröffentlichung per Link</strong>
        <div class="link-options" v-if="form">
            <b-form-group
                :label-cols-sm="3"
                :label-cols-lg="4"
                label-for="link-url"
                :label-size="size"
                label="Link zum Teilen"
                description=""
            >
                <b-input-group>
                    <b-input
                        id="link-url"
                        :size="size"
                        :value="form.form_url"
                        :disabled="true"
                    ></b-input>
                    <b-input-group-append>
                        <b-button
                            :size="size"
                            variant="primary"
                            :disabled="
                                busy.data ||
                                    busy.mutation ||
                                    busy.linkGeneration ||
                                    !hasFormUrl
                            "
                            v-b-modal.link-generation-modal
                            >Bearbeiten</b-button
                        >
                        <b-button
                            :size="size"
                            variant="warning"
                            :disabled="
                                busy.data ||
                                    busy.mutation ||
                                    busy.linkGeneration
                            "
                            v-b-modal.link-regeneration-modal
                            >Neu generieren</b-button
                        >
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <b-form-group
                :label-cols-sm="3"
                :label-cols-lg="4"
                label-for="link-url"
                :label-size="size"
                label="Zugriff auf das Formular"
                description=""
            >
                <b-button
                    :size="size"
                    variant="success"
                    :disabled="
                        busy.data ||
                            busy.mutation ||
                            busy.linkGeneration ||
                            !hasFormUrl ||
                            !allowedCopy
                    "
                    @click="copyFormUrl"
                    >Link kopieren</b-button
                >
                <b-button
                    class="ml-1"
                    :size="size"
                    variant="primary"
                    :href="form.form_url"
                    target="_blank"
                    :disabled="
                        busy.data ||
                            busy.mutation ||
                            busy.linkGeneration ||
                            !hasFormUrl
                    "
                    >Formular Tab öffnen</b-button
                >
            </b-form-group>
            <b-form-group
                :label-cols-sm="3"
                :label-cols-lg="4"
                label-for="qr-code"
                :label-size="size"
                label="QR Code"
                description=""
            >
                <b-img v-if="qr.data" :src="qr.data" width="150" />
                <b-dropdown
                    id="qr-download"
                    text="QR Code herunterladen"
                    :size="size"
                    :disabled="
                        busy.data ||
                            busy.mutation ||
                            busy.linkGeneration ||
                            !hasFormUrl
                    "
                >
                    <b-dropdown-item @click="downloadQrCode('png')"
                        >PNG</b-dropdown-item
                    >
                    <b-dropdown-item
                        :disabled="true"
                        @click="downloadQrCode('svg')"
                        >SVG</b-dropdown-item
                    >
                </b-dropdown>
            </b-form-group>
        </div>

        <ConfirmModal
            id="link-regeneration-modal"
            title="Link wirklich neu generieren?"
            @ok="handleGenerateToken"
        >
            Soll der Formularlink wirklich neu generiert werden? Diese Aktion
            kann nicht rückgängig gemacht werden und schon veröffentliche Links
            des Formulars funktionieren dann nicht mehr.
        </ConfirmModal>

        <EditModal
            v-if="form"
            id="link-generation-modal"
            title="Link anpassen"
            @ok="handleProvideToken"
            :busy="busy.linkGeneration"
        >
            <b-input-group :size="size">
                <b-input-group-prepend is-text
                    >{{ formUrlPrefix }}/</b-input-group-prepend
                >
                <b-input id="token" :size="size" v-model="newToken"></b-input>
            </b-input-group>
        </EditModal>
    </div>
</template>

<script>
import ApiFactory from "@/api/ApiFactory";
import ConfirmModal from "@/components/ConfirmModal/ConfirmModal";
import EditModal from "@/components/EditModal/EditModal";
import Size from "@/mixins/Size/Size";
import BlobUtil from "@/utils/BlobUtil";

import { mapActions, mapGetters } from "vuex";

const qrCodeApi = ApiFactory.get("qr");

export default {
    name: "FormsSharingSettings",
    props: ["id"],
    mixins: [Size],
    components: { ConfirmModal, EditModal },
    data() {
        return {
            activeOptions: [
                { text: "Aktiviert", value: 1 },
                { text: "Deaktiviert", value: 0 }
            ],
            allowedCopy: false,
            newToken: null,
            qr: {
                busy: false,
                data: null
            }
        };
    },
    created() {
        this.allowedCopy = !!navigator.clipboard;
    },
    watch: {
        form: {
            handler(val) {
                this.newToken = val.token;
            },
            deep: true
        },
        "form.form_url": {
            handler(val) {
                this.getQrCode(val);
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            busy: "forms/getBusy"
        }),
        form() {
            const form = this.$store.getters["forms/getForm"](this.id);
            return form;
        },
        formUrlPrefix() {
            return process.env.VUE_APP_FORM_URL;
        },
        hasFormUrl() {
            return this.form.form_url ? true : false;
        }
    },
    methods: {
        ...mapActions({
            generateToken: "forms/generateToken",
            provideToken: "forms/provideToken"
        }),
        async handleGenerateToken() {
            const answer = await this.generateToken(this.form.id);
            this.form.token = answer;
            this.$store.commit("forms/EDIT_FORM", this.form);
        },
        async handleProvideToken(event) {
            event.preventDefault();
            const answer = await this.provideToken({
                id: this.form.id,
                new_token: this.newToken
            });
            if (answer) {
                this.$bvModal.hide("link-generation-modal");
                this.form.token = this.newToken;
                this.$store.commit("forms/EDIT_FORM", this.form);
            }
        },
        copyFormUrl() {
            navigator.clipboard
                .writeText(this.form.form_url)
                .then(() => {
                    console.log("Text is on the clipboard.");
                })
                .catch(e => {
                    console.error(e);
                });
        },
        async fetchQrCode(data, format) {
            const response = await qrCodeApi.generate({
                data,
                format
            });
            if (response.ok) {
                return response.blob;
            }
        },
        async getQrCode(data) {
            if (data) {
                this.qr.busy = true;
                const qrCode = await this.fetchQrCode(data, "png");
                this.qr.data = BlobUtil.convertBlob(qrCode);
                this.qr.busy = false;
            }
        },
        async downloadQrCode(format) {
            if (this.form.form_url) {
                this.qr.busy = true;
                const qrCode = await this.fetchQrCode(
                    this.form.form_url,
                    format
                );
                BlobUtil.downloadBlob(qrCode, "qrcode");
                this.qr.busy = false;
            }
        }
    }
};
</script>
