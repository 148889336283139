<template>
    <div class="forms-detail-settings">
        <FormGroupBuilder v-if="form" :fields="ioFields" :data="form" />
        <b-form-group
            v-if="form"
            :label-cols-sm="3"
            :label-cols-lg="4"
            label-for="is-active"
            :label-size="size"
            label="Tags"
            description=""
            :disabled="busy.data || busy.mutation"
        >
            <CheckboxSelect
                :options="tagsSelect"
                :size="size"
                :busy="busy.data"
                :selectSize="5"
                v-model="form.tags"
            />
        </b-form-group>
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "FormsDetailSettings",
    props: ["id"],
    mixins: [Size],
    components: { FormGroupBuilder, CheckboxSelect },
    data() {
        return {
            ioFields: {
                id: {
                    key: "id",
                    label: "ID",
                    disabled: true
                },
                title: {
                    key: "title",
                    label: "Titel"
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            busy: "forms/getBusy",
            tags: "formsTags/getTags",
            tagsSelect: "formsTags/getTagsForSelect"
        }),
        form() {
            const form = this.$store.getters["forms/getForm"](this.id);
            return form;
        }
    },
    created() {
        this.fetchFormsTags();
    },
    methods: {
        ...mapActions({
            fetchFormsTags: "formsTags/fetchTags"
        })
    }
};
</script>
