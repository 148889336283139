<template>
    <MainContainer>
        <div class="forms-detail">
            <b-row>
                <b-col cols="12">
                    <h2>
                        <template v-if="!isNew">
                            <span
                                >Formular
                                <template v-if="form">
                                    "{{ form.title }}"
                                </template>
                                bearbeiten</span
                            >
                        </template>
                        <template v-else>
                            Neues Formular anlegen
                        </template>
                        <b-button
                            size="md"
                            variant="primary"
                            class=" ml-3 pull-right"
                            :disabled="busy.mutation"
                            @click="handleModification"
                        >
                            <Spinner
                                v-if="busy.mutation"
                                small
                                variant="light"
                            />
                            Speichern</b-button
                        >
                        <b-button
                            v-if="!isNew && form"
                            size="md"
                            variant="outline-primary"
                            class=" ml-1 pull-right"
                            :href="form.form_url"
                            target="_blank"
                        >
                            Formular ansehen</b-button
                        >
                        <b-button
                            v-if="!isNew"
                            size="md"
                            variant="outline-secondary"
                            class=" ml-1 pull-right"
                            :to="{ path: '/forms' }"
                        >
                            Zurück zum Hauptmenü</b-button
                        >
                    </h2>
                </b-col>
                <b-col cols="12">
                    <b-tabs content-class="mt-3">
                        <b-tab
                            title="Einstellungen"
                            :active="activeTab == 'settings'"
                        >
                            <b-tabs pills vertical>
                                <b-tab title="Allgemein">
                                    <FormsDetailSettings :id="id" />
                                </b-tab>
                                <b-tab title="Benachrichtigungen">
                                    <FormsNotificationSettings :id="id" />
                                </b-tab>
                                <b-tab title="Teilen">
                                    <FormsSharingSettings :id="id" />
                                </b-tab>
                                <b-tab title="Dankesseite">
                                    <FormsPlaceholderSettings :id="id" />
                                </b-tab>
                            </b-tabs>
                        </b-tab>
                        <b-tab
                            title="Erstellen"
                            :active="activeTab == 'creator'"
                        >
                            <FormsCreator :id="id" />
                        </b-tab>
                        <b-tab
                            title="Angemeldete"
                            lazy
                            :active="activeTab == 'answers'"
                        >
                            <FormsAnswers :id="id" />
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </div>
    </MainContainer>
</template>

<script>
import Size from "@/mixins/Size/Size";

import MainContainer from "@/components/MainContainer/MainContainer";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import FormsDetailSettings from "@/components/FormsDetailSettings/FormsDetailSettings";
import FormsNotificationSettings from "@/components/FormsNotificationSettings/FormsNotificationSettings";
import FormsSharingSettings from "@/components/FormsSharingSettings/FormsSharingSettings";
import FormsCreator from "@/components/FormsCreator/FormsCreator";
import FormsAnswers from "@/components/FormsAnswers/FormsAnswers";
import FormsPlaceholderSettings from "@/components/FormsPlaceholderSettings/FormsPlaceholderSettings";
import Spinner from "@/components/Spinner/Spinner";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "FormsDetail",
    mixins: [Size],
    components: {
        MainContainer,
        FormGroupBuilder,
        FormsDetailSettings,
        FormsSharingSettings,
        FormsNotificationSettings,
        FormsPlaceholderSettings,
        FormsCreator,
        FormsAnswers,
        Spinner
    },
    data() {
        return {
            activeTab: "settings"
        };
    },
    computed: {
        ...mapGetters({
            busy: "forms/getBusy",
            dirty: "forms/getDirty"
        }),
        id() {
            return this.$route.params.id;
        },
        isNew() {
            return this.id == "new";
        },
        form() {
            const form = this.$store.getters["forms/getForm"](this.id);
            return form;
        }
    },
    created() {
        this.fetch();

        const hash = this.$route.hash;
        if (hash) {
            this.activeTab = hash.replace("#", "");
        }
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav);
        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
        });
    },
    beforeRouteLeave(to, from, next) {
        if (this.dirty && !this.isNew) {
            if (!window.confirm("Ohne Speichern fortfahren?")) {
                return;
            }
        }
        next();
    },
    watch: {
        form: {
            handler() {
                if (!this.dirty) {
                    this.$store.commit("forms/SET_DIRTY", true);
                }
            },
            immediate: false,
            deep: true
        }
    },
    methods: {
        ...mapActions({
            fetchFormByID: "forms/fetchFormByID"
        }),
        async fetch() {
            await this.fetchFormByID(this.id);
            this.$store.commit("forms/SET_DIRTY", false);
        },
        async handleModification() {
            if (this.isNew) {
                const newForm = await this.$store.dispatch("forms/createForm", {
                    ...this.form
                });
                this.$router.push("/forms/" + newForm.id);
            } else {
                await this.$store.dispatch("forms/editForm", { ...this.form });
                this.$store.commit("forms/SET_DIRTY", false);
            }
        },
        preventNav(event) {
            if (!this.dirty || this.isNew) {
                return;
            }
            event.preventDefault();
            event.returnValue = "";
        }
    }
};
</script>
