<template>
    <div class="forms-placeholder-settings">
        <strong>Dankesseite</strong>
        <div class="thank-you-text mt-3" v-if="form">
            <vue-editor v-model="form.thank_you_text"></vue-editor>
        </div>
        <hr />
        <strong>Notiz bei inaktivem Formular</strong>
        <div class="disable-text mt-3" v-if="form">
            <vue-editor v-model="form.disable_text"></vue-editor>
        </div>
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";

import { VueEditor } from "vue2-editor";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "FormsPlaceholderSettings",
    props: ["id"],
    mixins: [Size],
    components: {
        VueEditor
    },
    data() {
        return {};
    },
    created() {},
    watch: {},
    computed: {
        ...mapGetters({
            busy: "forms/getBusy"
        }),
        form() {
            const form = this.$store.getters["forms/getForm"](this.id);
            return form;
        }
    },
    methods: {}
};
</script>
