<template>
    <div class="forms-answers">
        <b-row>
            <b-col cols="12">
                <b-button
                    v-if="!isNew"
                    variant="outline-primary"
                    size="sm"
                    @click="openModificationModal({ mode: 'create' })"
                    >Hinzufügen</b-button
                >
            </b-col>
            <b-col md="9" class="my-4">
                <TableFilter v-model="filter.pattern" />
            </b-col>
            <b-col md="3" class="my-4 text-right">
                <b-checkbox :size="size" v-model="exportValueNotText">
                    <i>Wert</i> statt <i>Inhalt exportieren</i>
                </b-checkbox>
                <TableExport
                    :busy="exportBusy"
                    :dataAvailable="dataAvailable"
                    :showPDF="false"
                    @exportExcel="exportExcel"
                    @exportPDF="exportPDF"
                />
            </b-col>
        </b-row>

        <b-table
            :items="answers"
            :fields="currentSelectedFields"
            :filter="filter.pattern"
            :busy="busy"
            :small="size == 'sm'"
            :sort-by.sync="sort.by"
            :sort-desc.sync="sort.desc"
            select-mode="multi"
            ref="selectableTable"
            empty-text="Keine Daten vorhanden"
            empty-filtered-text="Für den gewählten Filter wurden keine Daten gefunden"
            foot-clone
            no-footer-sorting
            selectable
            bordered
            striped
            responsive
            show-empty
            @row-selected="onRowSelected"
        >
            <template #thead-top>
                <template v-if="!busy && dataAvailable">
                    <b-tr>
                        <b-td>
                            <p class="small m-0">
                                Gesamt: {{ answers.length }}
                            </p>
                            <p class="small m-0">
                                Gewählt:
                                <template v-if="selected.length > 0"
                                    >{{ selected.length }}
                                </template>
                                <template v-else>-</template>
                            </p>
                        </b-td>
                        <b-td :colspan="colspan"></b-td>
                        <b-td class="text-right"
                            ><b-dropdown
                                variant="outline-secondary"
                                text="Spaltenauswahl"
                                :size="size"
                            >
                                <b-dropdown-form>
                                    <b-form-checkbox-group
                                        v-model="selectedFields"
                                        :options="recordFields"
                                        value-field="key"
                                        text-field="label"
                                    ></b-form-checkbox-group>
                                </b-dropdown-form>
                            </b-dropdown>
                        </b-td>
                    </b-tr> </template
            ></template>

            <template #table-busy>
                <div class="text-center text-secondary my-2">
                    <b-spinner class="mr-1 align-middle" small></b-spinner>
                    <strong>Daten werden geladen...</strong>
                </div>
            </template>

            <template #head(__selected)>
                <input
                    type="checkbox"
                    v-model="selectAll"
                    :true-value="true"
                    :false-value="false"
                    @change="toggleAllSelectedRows"
                />
            </template>

            <template #cell(__selected)="{ rowSelected }">
                <input
                    type="checkbox"
                    :checked="rowSelected"
                    :true-value="true"
                    :false-value="false"
                    disabled
                />
            </template>

            <template #cell()="row">
                <div class="d-inline" v-html="parseAnswer(row)"></div>
            </template>

            <template #cell(entry_date)="row">
                {{ formatDateTime(row.value) }}
            </template>

            <template #cell(data_privacy_approval)="row">
                <template v-if="row.value == 1">
                    <font-awesome-icon icon="circle" class="text-success" />
                </template>
                <template v-else>
                    <font-awesome-icon icon="circle" class="text-danger" />
                </template>
            </template>

            <template #cell(__actions)="data">
                <b-button
                    variant="outline-primary"
                    size="sm"
                    class="mx-1"
                    @click="
                        openModificationModal({
                            record: data.item,
                            mode: 'edit'
                        })
                    "
                    >Bearbeiten</b-button
                >
                <b-button
                    variant="outline-danger"
                    size="sm"
                    class="mx-1"
                    @click="openDeleteModal({ record: data.item })"
                    >Löschen</b-button
                >
            </template>
        </b-table>

        <EditModal
            :busy="exportBusy.mutation"
            :mode="modificationMode"
            @ok="handleModification"
            @hidden="hideEditModal"
        >
            <div v-for="field in editIoFields" :key="field.id">
                <b-form-group
                    :size="size"
                    :state="getValidationStateForField(field.id)"
                >
                    <template #label>
                        <template v-if="field.field_label">
                            <b>{{ field.field_label }}</b>
                        </template>
                        <template v-if="field.field_required == 1">
                            <span class="text-danger">*</span>
                        </template>
                    </template>

                    <template #invalid-feedback>
                        <p
                            v-for="feedback in getValidationFeedbackForField(
                                field.id
                            )"
                            :key="feedback"
                            class="feedback"
                        >
                            {{ feedback }}
                        </p>
                    </template>

                    <component
                        v-if="modificationRecord"
                        :is="field.component"
                        :field="field"
                        :frontend="true"
                        :suppressDisabled="true"
                        :state="getValidationStateForField(field.id)"
                        v-model="modificationRecord[field.id]"
                    />
                </b-form-group>
            </div>
            <hr />
            <div v-if="modificationRecord" class="data-privacy-approval">
                <b-form-group
                    :size="size"
                    :state="getValidationStateForField('data_privacy_approval')"
                >
                    <template #invalid-feedback>
                        <p
                            v-for="feedback in getValidationFeedbackForField(
                                'data_privacy_approval'
                            )"
                            :key="feedback"
                            class="feedback"
                        >
                            {{ feedback }}
                        </p>
                    </template>
                    <input
                        type="checkbox"
                        :true-value="1"
                        :false-value="0"
                        v-model="modificationRecord.data_privacy_approval"
                    />
                    Hiermit stimme ich der
                    <a href="#">Datenschutzerklärung</a> und der Verarbeitung
                    meiner Daten zu
                    <span class="text-danger">*</span>
                </b-form-group>
            </div>
            <template v-if="modificationRecord && !modificationRecord.id">
                <hr />
                <div>
                    <b-form-group
                        :size="size"
                        label="Bestätigungsmail an Teilnehmer*innen senden"
                    >
                        <b-checkbox
                            v-model="sendAdditionalRegisterConfirmation"
                        />
                    </b-form-group>
                </div>
            </template>
        </EditModal>

        <DeleteModal
            :mode="modificationMode"
            :record="modificationRecord"
            :fields="currentRecordFields"
            :selected="selected"
            :busy="exportBusy.mutation"
            @ok="deleteRecord"
        >
        </DeleteModal>
    </div>
</template>

<script>
import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import Forms from "@/mixins/Forms/Forms";
import DateFormat from "@/mixins/Date/Date";

import EditModal from "@/components/EditModal/EditModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";

import InputField from "@/components/FormsCreator/FieldTypes/InputField";
import TextareaField from "@/components/FormsCreator/FieldTypes/TextareaField";
import WYSIWYGField from "@/components/FormsCreator/FieldTypes/WYSIWYGField";
import EmailField from "@/components/FormsCreator/FieldTypes/EmailField";
import GenderField from "@/components/FormsCreator/FieldTypes/GenderField";
import SingleChoiceField from "@/components/FormsCreator/FieldTypes/SingleChoiceField";
import MultipleChoiceField from "@/components/FormsCreator/FieldTypes/MultipleChoiceField";
import DropdownField from "@/components/FormsCreator/FieldTypes/DropdownField";
import CounterField from "@/components/FormsCreator/FieldTypes/CounterField";
import StarRatingField from "@/components/FormsCreator/FieldTypes/StarRatingField";
import TitleField from "@/components/FormsCreator/FieldTypes/TitleField";

import { mapActions, mapGetters } from "vuex";

import "./FormsAnswers.scss";

export default {
    name: "FormsAnswers",
    props: ["id"],
    mixins: [ModelView, Size, Forms, DateFormat],
    components: {
        EditModal,
        DeleteModal,
        TableExport,
        TableFilter,
        InputField,
        TextareaField,
        WYSIWYGField,
        EmailField,
        GenderField,
        SingleChoiceField,
        MultipleChoiceField,
        DropdownField,
        CounterField,
        StarRatingField,
        TitleField
    },
    data() {
        return {
            filter: {
                pattern: ""
            },
            sort: {
                by: "entry_date",
                desc: false
            },
            actions: {
                create: "formsAnswers/createAnswer",
                edit: "formsAnswers/editAnswer",
                delete: "formsAnswers/deleteAnswers",
                deleteMulti: "formsAnswers/deleteMultipleAnswers"
            },
            predefinedFields: [
                {
                    key: "__selected",
                    label: "",
                    class: "text-center",
                    static: true,
                    position: "prepend"
                },
                {
                    key: "id",
                    label: "ID",
                    static: true,
                    position: "prepend",
                    sortable: true
                },
                {
                    key: "entry_date",
                    label: "Eingangsdatum",
                    static: true,
                    position: "prepend",
                    sortable: true
                },
                {
                    key: "data_privacy_approval",
                    label: "Datenschutz angenommen",
                    static: false,
                    position: "append",
                    class: "text-center",
                    sortable: true
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            sendAdditionalRegisterConfirmation: false,
            exportValueNotText: false
        };
    },
    computed: {
        ...mapGetters({
            exportBusy: "formsAnswers/getBusy"
        }),
        busy() {
            const busyAnswers = this.$store.getters["formsAnswers/getBusy"];
            const busyFields = this.$store.getters["formsFields/getBusy"];
            return busyAnswers.data || busyFields.data;
        },
        answers() {
            const processedAnswers = [];
            const answers = this.$store.getters["formsAnswers/getAnswers"];
            answers.forEach(item => {
                const value = item.contents;
                value.id = item.id;
                value.entry_date = item.entry_date;
                value.data_privacy_approval = item.data_privacy_approval;
                processedAnswers.push(value);
            });
            return processedAnswers;
        },
        fields() {
            var fields = this.$store.getters["formsFields/getFields"] ?? [];
            fields = fields.filter(this.filterFields).map(this.processFields);
            fields = fields.concat(this.predefinedFields);
            return fields;
        },
        editIoFields() {
            var ioFields = this.$store.getters["formsFields/getFields"];
            ioFields = ioFields.filter(this.filterFields);
            return ioFields;
        },
        dataAvailable() {
            return this.answers.length > 0;
        },
        colspan() {
            return this.currentRecordFields.length > 0
                ? this.currentRecordFields.length + 2
                : 3;
        },
        isNew() {
            return this.id == "new";
        }
    },
    mounted() {
        this.fetchAnswers(this.id);
        this.fetchFieldsAndRegister();
    },
    methods: {
        ...mapActions({
            fetchAnswers: "formsAnswers/fetchAnswers",
            fetchFields: "formsFields/fetchFields"
        }),
        async fetchFieldsAndRegister() {
            await this.fetchFields(this.id);
            this.fields.forEach(item => {
                if (item.static !== true) {
                    this.selectedFields.push(item.key);
                }
            });
        },
        filterFields(item) {
            return item.component !== "WYSIWYGField";
        },
        processFields(item) {
            // TODO: Add logic for interpretation of fields
            return {
                key: item.id.toString(),
                label: item.field_label ?? item.id.toString(),
                options: item.field_options,
                mail:
                    item.field_settings &&
                    item.field_settings.corresponder_mail == 1
                        ? true
                        : false,
                sortable: true
            };
        },
        async exportExcel(format) {
            this.$store.dispatch("formsAnswers/exportExcel", {
                id: this.id,
                format,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id"),
                exportValues: this.exportValueNotText
            });
        },
        async exportPDF(orientation = "P") {
            this.$store.dispatch("formsAnswers/exportPDF", {
                orientation,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        parseAnswer(val) {
            const field = val.field;
            var value = val.value;

            if (field.options) {
                if (Array.isArray(value)) {
                    if (value.length == 0) {
                        return "";
                    }
                    if (value.length == 1) {
                        value = this.findAnswer(field.options, value[0]);
                    } else {
                        value = value.reduce((p, c) => {
                            return (
                                this.findAnswer(field.options, p) +
                                "<br />" +
                                this.findAnswer(field.options, c)
                            );
                        });
                    }
                } else {
                    value = this.findAnswer(field.options, value);
                }
            }
            return value;
        },
        findAnswer(options, value) {
            const newValue = options.find(item => item.value == value);
            if (newValue) {
                value = newValue.text;
            }
            return value;
        },
        hideEditModal() {
            this.sendAdditionalRegisterConfirmation = false;
        },
        /** Override */
        async handleModification(event) {
            event.preventDefault();

            const data = { ...this.modificationRecord };

            var action = this.actions.edit;
            if (this.modificationMode == "create") {
                action = this.actions.create;
            }

            var record = {
                id: data.id,
                form_id: this.id,
                contents: data,
                entry_date: data.entry_date,
                send_additional_register_confirmation: this
                    .sendAdditionalRegisterConfirmation
            };
            delete record.contents.id;
            delete record.contents.entry_date;

            const response = await this.$store.dispatch(action, record);
            if (response.ok) {
                this.$bvModal.hide(this.editModalId);
            } else {
                if (response.json.error.validation) {
                    this.validation = response.json.error.validation;
                }
            }
        }
    }
};
</script>
