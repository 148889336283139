<template>
    <div class="forms-creator">
        <b-row>
            <b-col cols="3">
                <b-list-group class="field-type-list">
                    <b-list-group-item
                        class="field-type-list-item"
                        v-for="fieldType in fieldTypes"
                        :key="fieldType.key"
                        href="#"
                        :disabled="fieldType.disabled"
                        @click="addField(fieldType)"
                    >
                        <div>
                            <b-row>
                                <b-col cols="9" class="small">
                                    <h5 class="title">{{ fieldType.name }}</h5>
                                    <!-- <p class="description text-muted">
                                        {{ fieldType.description }}
                                    </p> -->
                                </b-col>
                                <b-col
                                    cols="3"
                                    class="align-self-center text-center"
                                >
                                    <template v-if="fieldType.icon">
                                        <b-icon
                                            :icon="fieldType.icon"
                                            font-scale="1.5"
                                        />
                                    </template>
                                    <template v-else>
                                        <b-icon
                                            icon="input-cursor-text"
                                            font-scale="1.5"
                                        />
                                    </template>
                                </b-col>
                            </b-row>
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
            <b-col cols="9">
                <template v-if="form">
                    <div
                        v-for="field in orderedFormFields"
                        :key="field.id"
                        class="fields"
                    >
                        <b-row
                            class="field"
                            :class="
                                isModifiedField(field.id)
                                    ? 'modified-field'
                                    : ''
                            "
                        >
                            <b-col cols="1">
                                <b-button-group vertical>
                                    <b-button
                                        :size="size"
                                        v-b-tooltip.hover.right
                                        title="Rauf"
                                        @click="reorderField(field, true)"
                                    >
                                        <font-awesome-icon icon="chevron-up" />
                                    </b-button>
                                    <b-button
                                        :size="size"
                                        variant="outline-secondary"
                                        v-b-tooltip.hover.right
                                        :title="field.name"
                                        :disabled="false"
                                    >
                                        <b-icon :icon="field.icon" />
                                    </b-button>
                                    <b-button
                                        :size="size"
                                        v-b-tooltip.hover.right
                                        title="Runter"
                                        @click="reorderField(field, false)"
                                    >
                                        <font-awesome-icon
                                            icon="chevron-down"
                                        />
                                    </b-button>
                                </b-button-group>
                            </b-col>
                            <b-col cols="10">
                                <b-form-group :size="size">
                                    <template #label>
                                        <template v-if="!field.hide.label">
                                            <template v-if="field.field_label">
                                                {{ field.field_label }}
                                            </template>
                                            <template v-else>
                                                <i class="text-secondary"
                                                    >Feldbezeichnung</i
                                                >
                                            </template>
                                        </template>
                                        <template v-if="!field.hide.subtext">
                                            <p
                                                class="small text-secondary mb-0"
                                            >
                                                <template
                                                    v-if="field.field_subtext"
                                                >
                                                    {{ field.field_subtext }}
                                                </template>
                                                <template v-else>
                                                    <i
                                                        >Detaillierte
                                                        Beschreibung</i
                                                    >
                                                </template>
                                            </p>
                                        </template>
                                    </template>

                                    <template #description>
                                        <!---
                                        <template v-if="!field.hide.subtext">
                                            <template
                                                v-if="field.field_subtext"
                                            >
                                                {{ field.field_subtext }}
                                            </template>
                                            <template v-else>
                                                <i
                                                    >Detailliertere
                                                    Feldbeschreibung</i
                                                >
                                            </template>
                                        ---></template
                                    >

                                    <component
                                        :is="field.component"
                                        :field="field"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="1">
                                <b-button-group vertical>
                                    <b-button
                                        :size="size"
                                        variant="info"
                                        v-b-tooltip.hover.left
                                        title="Duplizieren"
                                        @click="duplicateField(field)"
                                    >
                                        <font-awesome-icon icon="copy" />
                                    </b-button>
                                    <b-button
                                        v-if="field.editMode"
                                        :size="size"
                                        variant="primary"
                                        v-b-tooltip.hover.left
                                        title="Bearbeiten"
                                        @click="editField(field)"
                                    >
                                        <font-awesome-icon icon="edit" />
                                    </b-button>
                                    <b-button
                                        :size="size"
                                        variant="danger"
                                        v-b-tooltip.hover.left
                                        title="Löschen"
                                        @click="deleteField(field)"
                                    >
                                        <font-awesome-icon icon="trash" />
                                    </b-button>
                                </b-button-group>
                            </b-col>
                        </b-row>
                    </div>
                </template>
            </b-col>
        </b-row>
        <b-sidebar
            id="field-detail-sidebar"
            title="Feld bearbeiten"
            right
            shadow
            backdrop
            v-model="showFieldDetailSidebar"
            @hidden="clearModificationRecord"
        >
            <div class="px-3 py-2">
                <template v-if="modificationRecord">
                    <FormGroupBuilder
                        :fields="customIoFields"
                        :data="modificationRecord"
                    />
                    <template
                        v-if="
                            modificationRecord.component == 'DropdownField' ||
                                modificationRecord.component ==
                                    'SingleChoiceField' ||
                                modificationRecord.component ==
                                    'MultipleChoiceField'
                        "
                    >
                        <b-form-group
                            :label-cols-sm="3"
                            :label-cols-lg="4"
                            :label-size="size"
                            label="Optionen"
                        >
                            <div class="options">
                                <div
                                    v-for="(option,
                                    index) in modificationRecord.field_options"
                                    :key="index"
                                >
                                    <b-input-group class="mb-1">
                                        <b-input
                                            placeholder="Wert"
                                            v-model="option.value"
                                            :size="size"
                                            :disabled="true"
                                        />
                                        <b-input
                                            placeholder="Text"
                                            v-model="option.text"
                                            :size="size"
                                            :disabled="true"
                                        />

                                        <b-input-group-append>
                                            <b-button
                                                variant="danger"
                                                :size="size"
                                                @click="
                                                    deleteOption(option.value)
                                                "
                                            >
                                                <font-awesome-icon
                                                    icon="trash"
                                                />
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                                <div>
                                    <b-input-group class="mb-1">
                                        <b-input
                                            placeholder="Wert"
                                            v-model="
                                                newModificationRecordOption.value
                                            "
                                            :size="size"
                                        />
                                        <b-input
                                            placeholder="Text"
                                            v-model="
                                                newModificationRecordOption.text
                                            "
                                            :size="size"
                                        />

                                        <b-input-group-append>
                                            <b-button
                                                :size="size"
                                                @click="addOption"
                                            >
                                                <font-awesome-icon
                                                    icon="plus"
                                                />
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                        </b-form-group>
                        <b-form-group
                            :label-cols-sm="3"
                            :label-cols-lg="4"
                            :label-size="size"
                            label="Standartwert"
                        >
                            <b-select
                                :options="[
                                    { value: null, text: 'Keine Vorauswahl' },
                                    ...modificationRecord.field_options
                                ]"
                                v-model="modificationRecord.field_default_value"
                                :size="size"
                            />
                        </b-form-group>
                    </template>
                    <template
                        v-if="modificationRecord.component == 'StarRatingField'"
                    >
                        <b-form-group
                            :label-cols-sm="3"
                            :label-cols-lg="4"
                            :label-size="size"
                            label="Anzahl der Sterne"
                        >
                            <b-input
                                type="number"
                                v-model="
                                    modificationRecord.field_settings.stars
                                "
                                :size="size"
                                min="3"
                                max="10"
                            />
                        </b-form-group>
                    </template>
                    <template
                        v-if="modificationRecord.component == 'EmailField'"
                    >
                        <b-form-group
                            :label-cols-sm="3"
                            :label-cols-lg="4"
                            :label-size="size"
                            label="Email für Korrespondenz nutzen?"
                        >
                            <b-checkbox
                                switch
                                :size="size"
                                value="1"
                                unchecked-value="0"
                                v-model="
                                    modificationRecord.field_settings
                                        .corresponder_mail
                                "
                            ></b-checkbox>
                        </b-form-group>
                    </template>
                </template>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import shortid from "shortid";

import Size from "@/mixins/Size/Size";

import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import InputField from "./FieldTypes/InputField";
import TextareaField from "./FieldTypes/TextareaField";
import WYSIWYGField from "./FieldTypes/WYSIWYGField";
import EmailField from "./FieldTypes/EmailField";
import GenderField from "./FieldTypes/GenderField";
import SingleChoiceField from "./FieldTypes/SingleChoiceField";
import MultipleChoiceField from "./FieldTypes/MultipleChoiceField";
import DropdownField from "./FieldTypes/DropdownField";
import CounterField from "./FieldTypes/CounterField";
import StarRatingField from "./FieldTypes/StarRatingField";
import PartingLineField from "./FieldTypes/PartingLineField.vue";

import "./FormsCreator.scss";

export default {
    name: "FormsCreator",
    props: ["id"],
    components: {
        FormGroupBuilder,
        InputField,
        TextareaField,
        WYSIWYGField,
        EmailField,
        GenderField,
        SingleChoiceField,
        MultipleChoiceField,
        DropdownField,
        CounterField,
        StarRatingField,
        PartingLineField
    },
    mixins: [Size],
    data() {
        return {
            fieldTypes: [
                {
                    key: "input",
                    component: "InputField",
                    name: "Einzeilige Eingabe",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "textarea-t",
                    editMode: true
                },
                {
                    key: "textarea",
                    component: "TextareaField",
                    name: "Mehrzeilige Eingabe",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "card-text",
                    editMode: true
                },
                {
                    key: "wysiwyg",
                    component: "WYSIWYGField",
                    name: "Statischer Text",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "text-left",
                    editMode: false,
                    hide: {
                        label: true,
                        subtext: true
                    }
                },
                {
                    key: "email",
                    component: "EmailField",
                    name: "Emailadresse",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "at",
                    editMode: true,
                    field_settings: {
                        corresponder_mail: 1
                    }
                },
                {
                    key: "gender",
                    component: "GenderField",
                    name: "Geschlecht",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "",
                    editMode: true,
                    hide: {
                        placeholder: true
                    }
                },
                {
                    key: "address",
                    component: "AddressField",
                    name: "Anschrift",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "house-fill",
                    editMode: true,
                    disabled: true
                },
                {
                    key: "dropdown",
                    component: "DropdownField",
                    name: "Dropdown",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "menu-button-wide",
                    editMode: true,
                    hide: {
                        placeholder: true
                    }
                },
                {
                    key: "single-choice",
                    component: "SingleChoiceField",
                    name: "Single Choice",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "signpost-split-fill",
                    editMode: true,
                    hide: {
                        placeholder: true
                    }
                },
                {
                    key: "multiple-choice",
                    component: "MultipleChoiceField",
                    name: "Multiple Choice",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "signpost2-fill",
                    editMode: true,
                    hide: {
                        placeholder: true
                    }
                },
                {
                    key: "counter",
                    component: "CounterField",
                    name: "Zahlenfeld",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "",
                    editMode: true
                },
                {
                    key: "stars",
                    component: "StarRatingField",
                    name: "Sternebewertung",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "star-half",
                    editMode: true,
                    field_settings: {
                        stars: 5
                    }
                },
                {
                    key: "scale",
                    component: "ScaleRatingField",
                    name: "Skalenbewertung",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "bar-chart-fill",
                    editMode: true,
                    disabled: true
                },
                {
                    key: "parting-line",
                    component: "PartingLineField",
                    name: "Trennlinie",
                    description:
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
                    icon: "bar-chart-fill",
                    editMode: false,
                    hide: {
                        label: true,
                        subtext: true,
                        placeholder: true
                    }
                }
            ],
            showFieldDetailSidebar: false,
            modificationRecord: null,
            ioFields: {
                name: {
                    key: "name",
                    label: "Feldtyp",
                    disabled: true
                },
                field_label: {
                    key: "field_label",
                    label: "Beschreibung"
                },
                field_subtext: {
                    key: "field_subtext",
                    label: "Detaillierte Beschreibung"
                },
                field_placeholder: {
                    key: "field_placeholder",
                    label: "Platzhalter"
                },
                /*field_default: {
                    key: "field_default",
                    label: "Standartwert"
                },*/
                field_required: {
                    key: "field_required",
                    label: "Erforderlich",
                    type: "switch",
                    checked_value: "1",
                    unchecked_value: "0"
                },
                field_disabled: {
                    key: "field_disabled",
                    label: "Deaktiviert",
                    type: "switch",
                    checked_value: "1",
                    unchecked_value: "0"
                }
            },
            defaultHideOptions: {
                label: false,
                subtext: false,
                placeholder: false,
                default: false,
                options: false,
                required: false,
                disabled: false
            },
            newModificationRecordOption: {
                value: null,
                text: null
            },
            offsetTop: 0
        };
    },
    computed: {
        form() {
            const form = this.$store.getters["forms/getForm"](this.id);
            return form;
        },
        formFields: {
            get: function() {
                if (!this.form.fields) {
                    return [];
                }
                return this.form.fields.map(item => {
                    const fieldType = this.fieldTypes.find(
                        type => type.component == item.component
                    );
                    fieldType.hide = Object.assign(
                        { ...this.defaultHideOptions },
                        fieldType.hide
                    );
                    return Object.assign(item, fieldType);
                });
            },
            set: function(value) {
                this.$store.commit("forms/SET_FORM_FIELDS", {
                    form_id: this.id,
                    fields: value
                });
            }
        },
        orderedFormFields() {
            const formFields = [...this.formFields];
            return formFields.sort((a, b) =>
                a.field_position > b.field_position
                    ? 1
                    : b.field_position > a.field_position
                    ? -1
                    : 0
            );
        },
        customIoFields() {
            var customIoFields = { ...this.ioFields };
            if (this.modificationRecord) {
                const validIoFields = Object.keys(this.modificationRecord.hide)
                    .filter(
                        hideField => !this.modificationRecord.hide[hideField]
                    )
                    .map(field => "field_" + field);
                validIoFields.push("name");
                return Object.values(customIoFields).filter(item => {
                    return validIoFields.includes(item.key);
                });
            }
            return customIoFields;
        }
    },
    watch: {
        offsetTop: {
            handler(val) {
                this.handleViewport();
            }
        }
    },
    created() {
        window.addEventListener("scroll", this.onScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
        onScroll(e) {
            console.log("scrolling");
            this.offsetTop =
                window.pageYOffset || document.documentElement.scrollTop;
        },
        isElementInViewport(el) {
            var rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <=
                    (window.innerHeight ||
                        document.documentElement.clientHeight) &&
                rect.right <=
                    (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        handleViewport() {
            const item = document.querySelector(".field-type-list");
            if (!this.isElementInViewport(item)) {
                item.classList.add("scroll");
            }
        },
        addField(fieldType) {
            if (!fieldType.disabled) {
                const formField = Object.assign(
                    {
                        id: shortid.generate(),
                        form_id: this.id,
                        field_label: null,
                        field_placeholder: null,
                        field_subtext: null,
                        field_required: false,
                        field_disabled: false,
                        field_default_value: null,
                        field_options: [],
                        field_settings: {},
                        field_position: this.formFields.length
                    },
                    { ...fieldType }
                );

                const copyFormFields = [...this.formFields];
                copyFormFields.push(formField);
                this.formFields = copyFormFields;
            }
        },
        reorderField(field, up = true) {
            const copyFormFields = [...this.formFields];
            const currentFieldIndex = copyFormFields.findIndex(
                item => item.id == field.id
            );

            var swapFieldIndex = -1;
            if (up) {
                swapFieldIndex = copyFormFields.findIndex(
                    item => item.field_position == field.field_position - 1
                );
            } else {
                swapFieldIndex = copyFormFields.findIndex(
                    item => item.field_position == field.field_position + 1
                );
            }

            if (swapFieldIndex !== -1) {
                const newPosition =
                    copyFormFields[swapFieldIndex].field_position;

                copyFormFields[swapFieldIndex].field_position =
                    field.field_position;
                copyFormFields[currentFieldIndex].field_position = newPosition;
            }

            this.formFields = copyFormFields;
        },
        normalizeOrder(fields) {
            for (var i = 0; i < fields.length; i++) {
                fields[i].field_position = i;
            }
            return fields;
        },
        duplicateField(field) {
            const duplicateField = { ...field };
            duplicateField.id = shortid.generate();
            duplicateField.field_position = this.formFields.length;

            const copyFormFields = [...this.formFields];
            copyFormFields.push(duplicateField);
            this.formFields = copyFormFields;
        },
        editField(field) {
            this.showFieldDetailSidebar = true;
            this.modificationRecord = field;
        },
        deleteField(field) {
            const copyFormFields = [...this.formFields];
            var duplicatFields = copyFormFields.filter(
                item => item.id != field.id
            );
            duplicatFields = duplicatFields.sort(
                (a, b) => a.field_position - b.field_position
            );
            duplicatFields = this.normalizeOrder(duplicatFields);

            this.formFields = duplicatFields;
        },
        clearModificationRecord() {
            this.modificationRecord = null;
        },
        isModifiedField(field_id) {
            if (this.modificationRecord) {
                return this.modificationRecord.id == field_id;
            }
            return false;
        },
        addOption() {
            if (this.modificationRecord) {
                if (!this.newModificationRecordOption.value) {
                    this.newModificationRecordOption.value = shortid.generate();
                }
                this.modificationRecord.field_options.push({
                    value: this.newModificationRecordOption.value,
                    text: this.newModificationRecordOption.text
                });
                this.newModificationRecordOption = {
                    value: null,
                    text: null
                };
            }
        },
        deleteOption(value) {
            if (this.modificationRecord) {
                this.modificationRecord.field_options = this.modificationRecord.field_options.filter(
                    item => item.value !== value
                );
            }
        }
    }
};
</script>
