<template>
    <div class="forms-notification-settings">
        <FormGroupBuilder v-if="form" :fields="ioFields" :data="form" />
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";

import { mapGetters } from "vuex";

export default {
    name: "FormsNotificationSettings",
    props: ["id"],
    mixins: [Size],
    components: { FormGroupBuilder },
    data() {
        return {
            ioFields: {
                send_register_confirmation_header: {
                    key: "send_register_confirmation_header",
                    type: "headline",
                    text: "Bestätigungsmail an Teilnehmer*innen"
                },
                send_register_confirmation: {
                    key: "send_register_confirmation",
                    label: "Bestätigung an Teilnehmer*in versenden",
                    type: "checkbox",
                    checked_value: 1,
                    unchecked_value: 0
                },
                register_confirmation_sender_name: {
                    key: "register_confirmation_sender_name",
                    label: "Absendename"
                },
                register_confirmation_sender_mail: {
                    key: "register_confirmation_sender_mail",
                    label: "Absende Mailadresse",
                    type: "email"
                },
                register_confirmation_subject: {
                    key: "register_confirmation_subject",
                    label: "Betreff"
                },
                register_confirmation_text: {
                    key: "register_confirmation_text",
                    label: "Bestätigungstext",
                    type: "wysiwyg"
                },
                confirmation_divider: {
                    key: "confirmation_divider",
                    type: "hr"
                },
                send_register_return_header: {
                    key: "send_register_return_header",
                    type: "headline",
                    text: "Benachrichtigungen an Landesverband"
                },
                send_register_return: {
                    key: "send_register_return",
                    label: "Bestätigung an LV versenden",
                    type: "checkbox",
                    checked_value: 1,
                    unchecked_value: 0
                },
                register_return_sender_mail: {
                    key: "register_return_recipients",
                    label: "Empfängeradressen",
                    description:
                        "Es können mehrere Mailadressen mit , getrennt eingegeben werden"
                },
                register_return_subject: {
                    key: "register_return_subject",
                    label: "Betreff"
                },
                register_return_text: {
                    key: "register_return_text",
                    label: "Bestätigungstext",
                    type: "wysiwyg"
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            busy: "forms/getBusy"
        }),
        form() {
            const form = this.$store.getters["forms/getForm"](this.id);
            return form;
        }
    },
    watch: {
        "form.send_register_confirmation": {
            handler(val) {
                const boolVal = val == 0;
                this.ioFields.register_confirmation_sender_name.disabled = boolVal;
                this.ioFields.register_confirmation_sender_mail.disabled = boolVal;
                this.ioFields.register_confirmation_subject.disabled = boolVal;
                this.ioFields.register_confirmation_text.disabled = boolVal;
            },
            immediate: true
        },
        "form.send_register_return": {
            handler(val) {
                const boolVal = val == 0;
                this.ioFields.register_return_sender_mail.disabled = boolVal;
                this.ioFields.register_return_subject.disabled = boolVal;
                this.ioFields.register_return_text.disabled = boolVal;
            },
            immediate: true
        }
    }
};
</script>
